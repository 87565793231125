<template>
    <section class="bg-dark p-5 h-100 text-center d-flex align-items-center justify-content-center">
        <spinner class="text-white"></spinner>
    </section>
</template>
  
<script>
import Spinner from "../components/Spinner";
import axios from 'axios';
import { useToast } from 'vue-toastification';
import { mapActions } from "vuex";
export default {
    components: { Spinner },
    data() {
        return {}
    },
    created() {
        this.loadWhitelabelConfiguration();
    },
    methods: {
        ...mapActions('whitelabelConfiguration', {
            configureWhitelabel: 'configureWhitelabel'
        }),

        loadWhitelabelConfiguration() {
            const toast = useToast();

            let vm = this;
            let whitelabelSlug = this.whitelabelSlug;
            axios.get(window.API_BASE + '/whitelabel-configurations/slug/' + whitelabelSlug).then(r => {
                let whitelabelConfig = r.data;
                if (whitelabelConfig) {
                    this.configureWhitelabel(whitelabelConfig.id);
                    this.$nextTick(() => {
                        this.$forceUpdate();
                        this.$nextTick(() => {
                            this.$router.push('/landing');
                        });
                    });
                } else {
                    toast.error('Company not found');
                    vm.configureWhitelabel('unset');
                    vm.$forceUpdate();
                    vm.$nextTick(() => {
                        this.$router.push('/');
                    });
                }
            }).catch(e => {
                console.log(e);
                toast.error('Company not found');
                vm.configureWhitelabel('unset');
                vm.$forceUpdate();
                vm.$nextTick(() => {
                    vm.$router.push('/');
                });
            });
        }
    },
    computed: {
        whitelabelSlug() {
            return this.$route.params.slug;
        }
    },
    setup() {

    },
}
</script>
  
  
<style scoped lang="scss">
.text-primary {
    color: rgb(var(--primary-colour)) !important;
}
</style>